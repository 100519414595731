// src/pages/QAUser/QAUser.jsx
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Layout from "../../Layout/Layout";
import InspectionDialog from "../../components/QADetails/InspectionDialog";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import API from "../../api/v1";
import dayjs from "dayjs";
import useUserInfo from "../../hooks/userProfile";

const QAUser = () => {
    const { session_id } = useParams();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [qaData, setQaData] = useState([]);
    const {userInfo} = useUserInfo();
    const location = useLocation();
    const [zoneSelect] = useState(location.state?.zone || 'prod');

    const handleViewClick = (params) => {
        setSelectedRow(params.row);
        console.log(params.row);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleExport = async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const response = await API.exportSessionTraces({
                orgId,
                projectId,
                session_id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                responseType: 'blob',
                zone: zoneSelect
            });

            let blobData;
            if (response instanceof Blob) {
                blobData = response;
            } else if (response.data instanceof Blob) {
                blobData = response.data;
            } else {
                throw new Error('響應中沒有找到 Blob 數據');
            }
            let filename = `traces.csv`; // 默認文件名

            // 嘗試從 headers 獲取文件名
            if (response.headers && response.headers['content-disposition']) {
                const contentDisposition = response.headers['content-disposition'];
                const filenameMatch = contentDisposition.match(/filename=(.+)/i);
                if (filenameMatch && filenameMatch.length > 1) {
                    filename = filenameMatch[1].replace(/["']/g, '').trim();
                }
            }

            // 使用 Blob 創建 URL
            const url = window.URL.createObjectURL(blobData);

            // 創建一個臨時的 a 標籤來觸發下載
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Export failed:', error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const orgId = userInfo.organizations[0].id;
                const projectId = userInfo.organizations[0].projects[0].id;
                const response = await API.getSessionTraces({ orgId, projectId, session_id, zone: zoneSelect });
                if (response.code === 0 && response.data) {
                    setQaData(response.data.traces.map((trace, index) => ({
                        date: dayjs(trace.timestamp).format('YYYY/MM/DD'),
                        time: dayjs(trace.timestamp).format('HH:mm:ss'),
                        id: trace.id, // 使用索引作為臨時 ID
                        sessionId: trace.sessionId,
                        question: trace.input,
                        answer: trace.output,
                    })));
                } else {
                    console.error('API error:', response.message);
                }
            } catch (error) {
                // console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData().then(r => {});
    }, [userInfo, session_id, zoneSelect]);

    const columns = [
        { field: 'date', headerName: '日期', width: 110 },
        { field: 'time', headerName: '時間', width: 110 },
        { field: 'sessionId', headerName: '使用者識別 ID', width: 120,
            renderCell: (params) => (
                    params.value
            )},
        { field: 'question', headerName: '提問', flex: 1 },
        { field: 'answer', headerName: '回答', flex: 1.5 },
        {
            field: 'view',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleViewClick(params)}
                    sx={{
                        backgroundColor: '#CBA660',
                        '&:hover': { backgroundColor: '#B8935C' }
                    }}
                >
                    檢視
                </Button>
            ),
        },
    ];

    return (
        <Layout>
            <Box component="main" sx={{flexGrow: 1, p: 3, width: '100%'}}>
                <Typography variant={'h4'} sx={{textAlign: 'left', mb: 5, mt: 2}}>問答明細</Typography>

                <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIosIcon sx={{ fontSize: '12px !important' }}  />}
                        onClick={() => navigate(-1)}
                        sx={{
                            borderColor: 'rgba(0, 0, 0, 0.87)',
                            color: 'rgba(0, 0, 0, 0.87)',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                            mb: 2
                        }}
                    >
                        回上一頁
                    </Button>
                </Box>
                <Card elevation={1} raised={true} sx={{ width: '100%', maxWidth: '956px', mb: 3 }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2
                    }}>
                        <Typography variant="h6">ID: {session_id}</Typography>
                        <Button
                            variant="text"
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExport}
                            sx={{
                                color: '#CBA660',
                                '&:hover': { color: '#B8935C' },
                                textTransform: 'none',
                            }}
                        >
                            匯出
                        </Button>
                    </Box>

                    <DataGrid
                        rows={qaData}
                        rowHeight={72}
                        columns={columns}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        sx={{
                            '&.MuiDataGrid-root .MuiDataGrid-container--top [role=row], &.MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]': {
                                backgroundColor: 'rgba(203, 166, 96, 0.08)',
                            },
                            '& .MuiDataGrid-row': {
                                borderBottom: 'none',
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:nth-of-type(odd)': {
                                    backgroundColor: '#ffffff'
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: '#ffffff'
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                            },
                            '& .MuiDataGrid-filler': {
                                display: 'none',
                            }
                        }}
                    />
                </Card>

                <InspectionDialog
                    open={dialogOpen}
                    handleClose={handleCloseDialog}
                    rowData={selectedRow}
                />
            </Box>
        </Layout>
    );
};

export default QAUser;
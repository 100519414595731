import axios from "axios";
import {getLocalStorage, remLocalStorage, setLocalStorage} from '../utils/localStorage';
import {API_CONFIG} from './config';
import {getNavigate} from '../services/navigationService';
import tokenManagementService from '../services/tokenManagement';

/**
 * 創建 Axios 實例
 * @returns {import('axios').AxiosInstance} Axios 實例
 */
const createAxiosInstance = () => {
    let baseURL;
    if (process.env.NODE_ENV === 'development') {
        baseURL = API_CONFIG.DEV_BASE_URL;
    } else {
        // 生產環境使用當前網頁的來源 + '/api'
        const origin = window.location.origin;
        baseURL = `${origin}/api`;
    }
    return axios.create({
        baseURL,
        timeout: API_CONFIG.TIMEOUT,
    });
};

// 創建 Axios 實例
const instance = createAxiosInstance();

/**
 * 設置請求和響應攔截器
 * @param {import('axios').AxiosInstance} instance - Axios 實例
 */
const setupInterceptors = (instance) => {
    // 請求攔截器
    instance.interceptors.request.use(
        (config) => {
            const token = getLocalStorage('token');
            config.headers.lang = getLocalStorage('lang') || 'zh-TW';
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // 響應攔截器
    instance.interceptors.response.use(
        (response) => {
            const result = response.data;
            const url = response.config.url;
            // 處理錯誤響應
            if (result.err && !url.includes('/v1/user/modifyEmail') && !url.includes('/v1/user/modifyProfileImage')) {
                if (result.msg) {
                    // showAlertDialog(result.msg);
                }
                handleErrorResponse(result.errno);
                return null;
            }

            // 處理特定 URL 的響應
            if (url.includes('/login') && !url.includes('/loginWithMultiPass')) {
                handleLoginResponse(result);
            } else if (url.includes('/register')) {
                getNavigate()('/signUpEmailVerify');
            } else if (url.includes('/logout')) {
                tokenManagementService.handleTokenInvalid();
            }

            return result;
        },
        async (error) => {
            const originalRequest = error.config;
            // 處理 401 錯誤（未授權）
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const newToken = await tokenManagementService.refreshToken();
                    if (newToken) {
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        return instance(originalRequest);
                    }
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    tokenManagementService.handleTokenInvalid();
                }
            }

            // 處理 403 錯誤（禁止訪問）
            if (error.response && error.response.status === 403) {
                // showAlertDialog('您沒有權限執行此操作');
            }

            return Promise.reject(error);
        }
    );
};

// 設置攔截器
setupInterceptors(instance);

// 初始化 token 管理服務
tokenManagementService.initialize();

// 監聽 token 失效事件
window.addEventListener('tokenInvalid', () => {
    const navigate = getNavigate();
    if (navigate) navigate('/login');
});

/**
 * 處理錯誤響應
 * @param {number} errno - 錯誤代碼
 */
function handleErrorResponse(errno) {
    const navigate = getNavigate();
    if (!navigate) {
        console.error('Navigation function not set');
        return;
    }

    switch(errno) {
        case 104:
            navigate('/alreadyExist');
            break;
        case 45:
        case 95:
        case 97:
            tokenManagementService.handleTokenInvalid();
            break;
        default:
            // 處理其他錯誤
            break;
    }
}

/**
 * 處理登入響應
 * @param {Object} result - 登入響應結果
 */
function handleLoginResponse(result) {
    const navigate = getNavigate();
    if (!navigate) {
        console.error('Navigation function not set');
        return;
    }

    if (result && !result.err && result.data.canLogin && result.data.token) {
        tokenManagementService.setToken(result.data.token, result.data.expire);
        remLocalStorage('email');

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('redirectUrl')) {
            // 處理重定向
            // TODO: 實作重定向邏輯
        } else {
            navigate('/dashboard');
        }
    } else {
        navigate('/signUpEmailVerify');
    }
}

/**
 * 發送 HTTP 請求
 * @param {import('axios').AxiosRequestConfig} options - 請求配置
 * @returns {Promise} 請求的 Promise 對象
 */
export default function request(options) {
    return instance(options);
}
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const NavigationSetter = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() => {
        setNavigate(navigate);
    }, [navigate]);
    return children;
};


/**
 * @file 導航服務
 * @description 提供全局可用的導航方法
 */

let navigate = null;

/**
 * @function setNavigate
 * @description 設置導航函數
 * @param {Function} nav - 從 react-router-dom 獲得的 navigate 函數
 */
export const setNavigate = (nav) => {
    navigate = nav;
};

/**
 * @function getNavigate
 * @description 獲取導航函數
 * @returns {Function|null} 導航函數，如果未設置則返回 null
 */
export const getNavigate = () => navigate;
// Layout.jsx
import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import Navbar from '../components/Navbar/Navbar';
import Menu from '../components/Menu/Menu';
import useUserInfo from "../hooks/userProfile"; // 確保路徑正確

// 設定抽屜寬度常量
const drawerWidth = 240;

/**
 * Layout 組件
 *
 * 這個組件提供了一個通用的頁面布局，包括頂部的 Navbar 和左側的 Menu。
 * 它被設計為包裝其他頁面組件，提供一致的頁面結構。
 *
 * @param {Object} props - 組件屬性
 * @param {React.ReactNode} props.children - 子組件，通常是頁面的主要內容
 * @returns {JSX.Element} 渲染的 Layout 組件
 */
const Layout = ({ children }) => {
    const { userInfo, loading, error } = useUserInfo();

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        // 您可以決定如何處理錯誤，這裡只是一個簡單的示例
        return <Box>錯誤: {error}</Box>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* 頂部導航欄 */}
            <Navbar userInfo={userInfo} />
            <Box sx={{ display: 'flex', height: '100%', alignItems: 'stretch', justifyContent: 'flex-start' }}>
                {/* 左側菜單 */}
                <Menu />
                {/* 主要內容區域 */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default Layout;
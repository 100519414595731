// src/components/InspectionDialog/InspectionDialog.jsx
import React, {useState} from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, IconButton, Snackbar, Alert
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const InspectionDialog = ({open, handleClose, rowData}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const version = process.env.REACT_APP_PUBLISH_VERSION;
    const handleCopy = () => {
        const textToCopy = `
            檢視完整問答
            當前版本: ${version}
            問答識別 ID: ${rowData?.id || ''}
            使用者識別 ID: ${rowData?.userId || ''}
            日期: ${rowData?.date || ''}
            時間: ${rowData?.time || ''}
            提問: ${rowData?.question || ''}
            回答: ${rowData?.answer || ''}
            --------------------------------------------------
        `.trim();
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setSnackbarOpen(true);
                // 可以在這裡添加一個成功提示,比如使用Snackbar
            })
            .catch(err => {
                // 可以在這裡添加一個錯誤提示
            });
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>檢視完整問答</DialogTitle>
                <IconButton
                    onClick={handleCopy}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <ContentCopyIcon/>
                </IconButton>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, mt: 2}}>
                        <TextField
                            label="使用者識別 ID"
                            value={rowData?.userId || ''}
                            fullWidth
                            InputProps={{readOnly: true}}
                        />
                        <Box sx={{display: 'flex', gap: 2}}>
                            <TextField
                                label="日期"
                                value={rowData?.date || ''}
                                fullWidth
                                InputProps={{readOnly: true}}
                            />
                            <TextField
                                label="時間"
                                value={rowData?.time || ''}
                                fullWidth
                                InputProps={{readOnly: true}}
                            />
                        </Box>
                        <TextField
                            label="提問"
                            value={rowData?.question || ''}
                            fullWidth
                            multiline
                            rows={2}
                            InputProps={{readOnly: true}}
                        />
                        <TextField
                            label="回答"
                            value={rowData?.answer || ''}
                            fullWidth
                            multiline
                            rows={12}
                            InputProps={{readOnly: true}}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{
                            backgroundColor: '#CBA660', color: 'white', '&:hover': {backgroundColor: '#B8935C'}
                        }}
                    >
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    已成功複製內容到剪貼板
                </Alert>
            </Snackbar>
        </>
    );
};

export default InspectionDialog;
import React, {useCallback, useRef, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress,
    Box,
    Alert,
    Snackbar,
    Link,
    Tooltip
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import API from "../../api/v1";
import {formatDate} from "../../utils/dateUtils";
import {ReactComponent as CsvIcon} from "../../assets/images/csv.svg"
import {ReactComponent as TxtIcon} from "../../assets/images/txt.svg"

/**
 * 檔案上傳表格組件
 * 顯示公版、檔案名稱、上傳時間和上傳按鈕
 * 每個表格單元格的寬度相等
 */
const FileUploadTable = ({documents = [], orgId, projectId, knowbaseId, onDocumentsUpdate, onRefreshData}) => {
    const [uploadingStates, setUploadingStates] = useState({});
    const [downloading, setDownloading] = useState({});
    const fileInputRefs = useRef({});
    const [fileError, setFileError] = useState(null);

    // 預定義的文檔類型
    const defaultDocTypes = ['base_knowledge', 'basic', 'brand', 'dining', 'faq'];

    const getVersionName = (type) => {
        switch(type) {
            case 'base_knowledge': return '基礎知識';
            case 'basic': return '基本資料';
            case 'brand': return '櫃位品牌';
            case 'dining': return '餐飲資訊';
            case 'faq': return 'FAQ';
            default: return type;
        }
    };

    const getFileFormat = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return extension === 'csv' ? 'csv' : 'txt';
    };

    const handleUploadClick = (docId) => {
        fileInputRefs.current[docId].click();
    };

    // 處理文件下載
    const handleDownload = async (documentId, fileName) => {
        if (!documentId || !fileName) return;

        setDownloading(prev => ({ ...prev, [documentId]: true }));
        try {
            const response = await API.downloadDocument(orgId, projectId, knowbaseId, documentId);

            // 創建一個 Blob 對象
            const blob = new Blob([response], {
                type: getFileFormat(fileName) === 'csv'
                    ? 'text/csv'
                    : 'text/plain'
            });

            // 創建下載鏈接
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);

            // 觸發下載
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('下載文件時出錯:', error);
            setFileError('下載文件時發生錯誤');
        } finally {
            setDownloading(prev => ({ ...prev, [documentId]: false }));
        }
    };

    const handleFileChange = useCallback(async (event, docId, docType) => {
        const file = event.target.files[0];
        if (file) {
            setUploadingStates(prev => ({ ...prev, [docId]: true }));
            try {
                const format = getFileFormat(file.name);
                const formData = new FormData();
                formData.append('category', 'essential');
                formData.append('type', docType);
                formData.append('file', file);
                formData.append('fileName', file.name);
                formData.append('format', format);

                const response = await API.uploadDocument(orgId, projectId, knowbaseId, formData);

                if (response.code === 0) {
                    onDocumentsUpdate(prevDocs =>
                        prevDocs.map(doc =>
                            doc.type === docType ? {...response.data, format} : doc
                        )
                    );
                    onRefreshData();
                } else {
                    setFileError(response.message)
                    throw new Error(response.message || '上傳失敗');
                }
            } catch (error) {
                console.error('上傳文件時出錯:', error);
            } finally {
                setUploadingStates(prev => ({ ...prev, [docId]: false }));
            }
        }
    }, [orgId, projectId, knowbaseId, onDocumentsUpdate, onRefreshData]);

    // 合併實際文檔和預定義類型
    const allDocuments = defaultDocTypes.map(type => {
        const existingDoc = documents.find(doc => doc.type === type);
        return existingDoc || { id: type, type, fileName: '', updatedAt: '', format: '' };
    });

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="file upload table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: 'rgba(203, 166, 96, 0.08)' }}>
                        <TableCell width="15%">公版</TableCell>
                        <TableCell width="30%">檔案名稱</TableCell>
                        <TableCell width="15%">檔案類型</TableCell>
                        <TableCell width="20%">上傳時間</TableCell>
                        <TableCell width="20%"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allDocuments.map((doc) => (
                        <TableRow
                            key={doc.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell width="15%" component="th" scope="row">
                                {getVersionName(doc.type)}
                            </TableCell>
                            <TableCell width="30%" sx={{textAlign: "left"}}>
                                {doc.fileName ? (
                                    downloading[doc.id] ? (
                                        <Box display="flex" alignItems="center">
                                            <CircularProgress size={16} sx={{ mr: 1 }} />
                                            下載中...
                                        </Box>
                                    ) : (
                                        <Tooltip title="點擊下載文件">
                                            <Link
                                                component="button"
                                                onClick={() => handleDownload(doc.id, doc.fileName)}
                                                sx={{
                                                    textAlign: 'left',
                                                    color: 'rgb(0 0 0 / 87%)',
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                {doc.fileName}
                                            </Link>
                                        </Tooltip>
                                    )
                                ) : ''}
                            </TableCell>
                            <TableCell width="15%">
                                <Box display="flex" alignItems="center">
                                    {doc.fileName && (
                                        getFileFormat(doc.fileName) === 'csv'
                                            ? <CsvIcon />
                                            : <TxtIcon />
                                    )}
                                    {doc.fileName && (
                                        getFileFormat(doc.fileName).toUpperCase() || '尚未上傳'
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell width="20%">{formatDate(doc.updatedAt)}</TableCell>
                            <TableCell width="20%">
                                <input
                                    type="file"
                                    ref={el => fileInputRefs.current[doc.id] = el}
                                    style={{display: 'none'}}
                                    onChange={(e) => handleFileChange(e, doc.id, doc.type)}
                                    accept=".csv,.txt"
                                />
                                <Button
                                    startIcon={uploadingStates[doc.id] ? <CircularProgress size={20}/> :
                                        <UploadFileIcon/>}
                                    variant="contained"
                                    size={'small'}
                                    onClick={() => handleUploadClick(doc.id)}
                                    disabled={uploadingStates[doc.id]}
                                    sx={{
                                        backgroundColor: '#CBA660',
                                        '&:hover': {
                                            backgroundColor: '#A88B4F'
                                        }
                                    }}
                                >
                                    {uploadingStates[doc.id] ? '上傳中' : '上傳'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Snackbar open={!!fileError} autoHideDuration={6000} onClose={() => setFileError('')}>
                <Alert onClose={() => setFileError('')} severity="error" sx={{ width: '100%' }}>
                    {fileError}
                </Alert>
            </Snackbar>
        </TableContainer>
    );
};

export default FileUploadTable;
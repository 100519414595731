// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import API from "./api/v1/index";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

let authDomain = null;
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authDomain: authDomain,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

// 異步設置 provider 資訊
export const fetchProvider = async () => {
    try {
        const response = await API.providers({});
        if (response.data?.gcip) {
            // 更新 authDomain
            auth.config.authDomain = response.data.gcip.auth_domain;
            // 設置 tenantId
            auth.tenantId = response.data.gcip.tenant_id;
        }
    } catch (error) {
        console.error('Failed to fetch provider:', error);
    }
};

export {app, auth };
import { isDebugMode } from "./utils";

/**
 * 取得Local Storage資料
 * @param {string} name 儲存名稱
 * @return {string|boolean|null} 資料
 */
export const getLocalStorage = (name) => {
    // if (isDebugMode()) {
        return localStorage.getItem(name);
    // } else {
    //     const newName = window.btoa(encodeURIComponent(name));
    //     const data = localStorage.getItem(newName);
    //
    //     if (!data) {
    //         return null;
    //     } else if (data === 'true' || data === '1') {
    //         return true;
    //     } else if (data === 'false' || data === '0') {
    //         return false;
    //     } else {
    //         if (Boolean(data)) {
    //             return decodeURIComponent(window.atob(data));
    //         } else {
    //             return data;
    //         }
    //     }
    // }
};

/**
 * 使用Local Storage儲存資料
 * @param {string} name 儲存名稱
 * @param {string} data 資料
 * @return {void}
 */
export const setLocalStorage = (name, data) => {
    // if (isDebugMode()) {
        localStorage.setItem(name, data);
    // } else {
    //     if (Boolean(data)) {
    //         localStorage.setItem(window.btoa(encodeURIComponent(name)), window.btoa(encodeURIComponent(data)));
    //     } else {
    //         localStorage.setItem(window.btoa(encodeURIComponent(name)), data);
    //     }
    // }
};

/**
 * 刪除Local Storage資料
 * @param {string} name 儲存名稱
 * @return {void}
 */
export const remLocalStorage = (name) => {
    // if (isDebugMode()) {
        localStorage.removeItem(name);
    // } else {
    //     const newName = window.btoa(encodeURIComponent(name));
    //     localStorage.removeItem(newName);
    // }
};

/**
 * 清除Local Storage所有資料
 * @return {void}
 */
export const cleanLocalStorage = () => localStorage.clear();

/**
 * Set Domain
 * @param {string} domain
 * @return {void}
 */
export const setDomain = (domain) => {
    if (document.domain) {
        document.domain = domain;
    }
};
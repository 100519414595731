// src/utils/dateUtils.js

import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * 將日期字符串格式化為指定格式的台北時間
 * @param {string} dateString - ISO 格式的日期字符串
 * @param {string} [defaultValue="尚未更新"] - 如果日期無效時返回的默認值
 * @returns {string} 格式化後的日期字符串
 */
export const formatDate = (dateString, defaultValue = "尚未更新") => {
    if (!dateString) return defaultValue;
    try {
        const date = parseISO(dateString);
        return formatInTimeZone(date, 'Asia/Taipei', 'yyyy/MM/dd HH:mm');
    } catch (error) {
        console.error('日期格式化錯誤:', error);
        return defaultValue;
    }
};

/**
 * 將日期字符串格式化為指定格式的台北時間，支持自定義格式
 * @param {string} dateString - ISO 格式的日期字符串
 * @param {string} [format="yyyy/MM/dd HH:mm"] - 自定義的日期格式
 * @param {string} [defaultValue="尚未更新"] - 如果日期無效時返回的默認值
 * @returns {string} 格式化後的日期字符串
 */
export const formatDateCustom = (dateString, format = "yyyy/MM/dd HH:mm", defaultValue = "尚未更新") => {
    if (!dateString) return defaultValue;
    try {
        const date = parseISO(dateString);
        return formatInTimeZone(date, 'Asia/Taipei', format);
    } catch (error) {
        console.error('日期格式化錯誤:', error);
        return defaultValue;
    }
};
import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Switch, styled, CircularProgress
} from "@mui/material";
import API from "../../api/v1";

const CustomSwitch = styled(Switch)({
    '& .MuiSwitch-switchBase': {
        transition: 'all 0.2s ease',
        '&.Mui-checked': {
            color: '#CBA660',
            transform: 'translateX(20px)',
            '&:hover': {
                backgroundColor: 'rgba(203, 166, 96, 0.08)'
            },
            '& + .MuiSwitch-track': {
                backgroundColor: '#CBA660 !important',
                opacity: 0.5,
                border: 0
            }
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA !important',
        opacity: 1,
        transition: 'background-color 0.2s ease'
    },
    '& .MuiSwitch-thumb': {
        transition: 'all 0.2s ease'
    }
});

const UsageLimitTab = forwardRef(({ orgId, projectId }, ref) => {
    const [isServiceEnabled, setIsServiceEnabled] = useState(false);
    const [limitDuration, setLimitDuration] = useState("");
    const [limitMax, setLimitMax] = useState("");
    const [limitMessage, setLimitMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // 驗證錯誤狀態
    const [errors, setErrors] = useState({
        limitDuration: false,
        limitMax: false,
        limitMessage: false
    });

    // 獲取初始數據
    useEffect(() => {
        fetchRateLimit();
    }, [orgId, projectId]);

    const fetchRateLimit = async () => {
        try {
            setLoading(true);
            const response = await API.getRateLimit(orgId, projectId);
            if (response.code === 0 && response.data) {
                const { enabled, limitMax, limitDuration, limitMessage } = response.data;
                setIsServiceEnabled(enabled);
                setLimitDuration(limitDuration.toString());
                setLimitMax(limitMax.toString());
                setLimitMessage(limitMessage);
            } else {
                throw new Error(response.message || '獲取設定失敗');
            }
        } catch (err) {
            console.error('獲取設定失敗:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const validateFields = () => {
        if (!isServiceEnabled) return true;

        const newErrors = {
            limitDuration: !limitDuration || limitDuration.trim() === "",
            limitMax: !limitMax || limitMax.trim() === "",
            limitMessage: !limitMessage || limitMessage.trim() === ""
        };

        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };


    // 暴露獲取數據的方法給父組件
    useImperativeHandle(ref, () => ({
        validateFields,
        async getUsageLimitData() {
            if (validateFields()) {
                try {
                    const data = {
                        enabled: isServiceEnabled,
                        limitDuration: parseInt(limitDuration),
                        limitMax: parseInt(limitMax),
                        limitMessage: limitMessage
                    };

                    const response = await API.updateRateLimit(orgId, projectId, data);
                    if (response.code === 0) {
                        return data;
                    } else {
                        throw new Error(response.message || '更新失敗');
                    }
                } catch (error) {
                    throw error;
                }
            }
            return null;
        }
    }));

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress sx={{ color: '#CBA660' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <FormControlLabel
                control={
                    <CustomSwitch
                        checked={isServiceEnabled}
                        onChange={(e) => setIsServiceEnabled(e.target.checked)}
                    />
                }
                label={isServiceEnabled ? "開啟" : "關閉"}
                sx={{width: 'fit-content'}}
            />

            <Box sx={{ mt: 4, textAlign: 'left' }}>
                <Typography variant="h6" sx={{ mb: 1 }}>問隔時間(單位：秒)</Typography>
                <TextField
                    type="number"
                    value={limitDuration}
                    onChange={(e) => {
                        setLimitDuration(e.target.value);
                        setErrors(prev => ({...prev, limitDuration: false}));
                    }}
                    disabled={!isServiceEnabled}
                    error={errors.limitDuration}
                    helperText={errors.limitDuration ? "請輸入內容" : ""}
                    sx={{
                        width: '100%',
                        maxWidth: '170px',
                        mb: 1,
                        '& .Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mb: 4, display: 'block' }}>
                    從第一個問題互動開始計算問隔時間，最低限制為 30 秒
                </Typography>

                <Typography variant="h6" sx={{ mb: 1 }}>相同IP間隔時間內發問次數上限</Typography>
                <TextField
                    fullWidth
                    type="number"
                    value={limitMax}
                    onChange={(e) => {
                        setLimitMax(e.target.value);
                        setErrors(prev => ({...prev, limitMax: false}));
                    }}
                    disabled={!isServiceEnabled}
                    error={errors.limitMax}
                    helperText={errors.limitMax ? "請輸入內容" : ""}
                    sx={{
                        width: '100%',
                        maxWidth: '170px',
                        mb: 1,
                        '& .Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mb: 4, display: 'block' }}>
                    在設定的間隔時間內，同一IP位址只能發問此設定次數
                </Typography>

                <Typography variant="h6" sx={{ mb: 1 }}>超過發問次數的固定回覆</Typography>
                <TextField
                    fullWidth
                    value={limitMessage}
                    onChange={(e) => {
                        setLimitMessage(e.target.value);
                        setErrors(prev => ({...prev, limitMessage: false}));
                    }}
                    disabled={!isServiceEnabled}
                    error={errors.limitMessage}
                    helperText={errors.limitMessage ? "請輸入內容" : ""}
                    sx={{
                        width: '100%',
                        maxWidth: '564px',
                        mb: 1,
                        '& .Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mb: 4, display: 'block' }}>
                    當使用者超過發問次數限制時，會收到此固定回覆
                </Typography>
            </Box>
        </Box>
    );
});

export default UsageLimitTab;
import React, {useEffect} from 'react';
import {BrowserRouter as Router, useRoutes} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import './App.css';
import {auth} from './firebase'; // 導入 auth 實例
import routes from './routes';
import {getLocalStorage} from "./utils/localStorage";
import tokenManagementService from "./services/tokenManagement";
import initLicense from "./license";
import {ChatbotProvider} from './contexts/ChatbotContext';

/**
 * @function PrivateRoute
 * @description 用於保護需要身份驗證的路由
 * @param {Object} props - 組件屬性
 * @returns {JSX.Element} 根據身份驗證狀態渲染相應的元件
 */
const AppRoutes = () => {
    const [user, loading] = useAuthState(auth);
    const routeElements = useRoutes(routes(user));

    if (loading) {
        return <div>載入中...</div>;
    }

    return routeElements;
};

/**
 * @function App
 * @description 應用程式的根組件，設置路由和導航
 * @returns {JSX.Element} 渲染整個應用程式的結構
 */
function App() {
    useEffect(() => {
        initLicense();
        const token = getLocalStorage('token');
        const expireTime = getLocalStorage('expire');
        if (token && expireTime) {
            tokenManagementService.setToken(token, expireTime);
        }
    }, []);

    return (
        <ChatbotProvider>
            <Router>
                <div className="App">
                    <AppRoutes/>
                </div>
            </Router>
        </ChatbotProvider>
    );
}

export default App;
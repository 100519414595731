// 側邊欄菜單組件
import React from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Box, Typography } from '@mui/material';
import {Home, ManageAccounts, Book, QuestionAnswer, SmartToy, BarChart} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 256;

/**
 * 永久顯示的側邊欄菜單組件
 * @returns {JSX.Element} 渲染的菜單組件
 */
const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const version = process.env.REACT_APP_PUBLISH_VERSION

    // 菜單項配置
    const menuItems = [
        {text: '首頁', icon: <Home/>, path: '/'},
        {text: '帳戶管理', icon: <ManageAccounts/>, path: '/account-management'},
        {text: 'AI客服設定', icon: <SmartToy/>, path: '/ai-service-settings'},
        {text: '知識庫', icon: <Book/>, path: '/knowledge-base'},
        {text: '問答明細', icon: <QuestionAnswer/>, path: '/qa-details'},
        {text: '用量與費用', icon: <BarChart />, path: '/usage-and-fees' },
    ];

    return (
        // Drawer 組件：創建一個永久顯示的側邊欄
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                position: 'relative',
                minHeight: 'calc(100vh - 56px)',
                paddingTop: '5px',
                marginRight: '60px',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    position: 'relative',
                    height: '100%',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            {/* Box 組件：用於包裝 Drawer 的內容 */}
            <Box sx={{ overflow: 'auto' }}>
                {/* List 組件：包含所有菜單項 */}
                <List>
                    {/* 遍歷 menuItems 數組，為每個項目創建一個 ListItemButton */}
                    {menuItems.map((item) => (
                        <ListItemButton
                            key={item.text}
                            onClick={() => navigate(item.path)}
                            selected={location.pathname === item.path}
                        >
                            {/* ListItemIcon 組件：顯示菜單項的圖標 */}
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            {/* ListItemText 組件：顯示菜單項的文本 */}
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    ))}
                </List>
            </Box>
            <Typography variant={'body2'} color={'gray'} sx={{position: 'fixed', bottom: '10px', left: '20px'}}>{version}</Typography>
        </Drawer>
    );
};

export default Menu;
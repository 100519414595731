import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Switch,
    styled, CircularProgress
} from "@mui/material";
import API from "../../api/v1";

const CustomSwitch = styled(Switch)({
    '& .MuiSwitch-switchBase': {
        transition: 'all 0.2s ease',
        '&.Mui-checked': {
            color: '#CBA660',
            transform: 'translateX(20px)',
            '&:hover': {
                backgroundColor: 'rgba(203, 166, 96, 0.08)'
            },
            '& + .MuiSwitch-track': {
                backgroundColor: '#CBA660 !important',
                opacity: 0.5,
                border: 0
            }
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA !important',
        opacity: 1,
        transition: 'background-color 0.2s ease'
    },
    '& .MuiSwitch-thumb': {
        transition: 'all 0.2s ease'
    }
});

const OperationStatusTab = forwardRef(({ orgId, projectId }, ref) => {
    const [isOperationEnabled, setIsOperationEnabled] = useState(false);
    const [disabledMessage, setDisabledMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messageError, setMessageError] = useState(false);

    // 獲取初始數據
    useEffect(() => {
        fetchActivation();
    }, [orgId, projectId]);

    const fetchActivation = async () => {
        try {
            setLoading(true);
            const response = await API.getActivation(orgId, projectId);
            if (response.code === 0 && response.data) {
                setIsOperationEnabled(response.data.enabled);
                setDisabledMessage(response.data.disabledMessage);
            } else {
                throw new Error(response.message || '獲取設定失敗');
            }
        } catch (err) {
            console.error('獲取設定失敗:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const validateFields = () => {
        if (!isOperationEnabled) return true;

        const hasError = !disabledMessage || disabledMessage.trim() === "";
        setMessageError(hasError);
        return !hasError;
    };

    useImperativeHandle(ref, () => ({
        validateFields,
        async getOperationStatusData() {
            if (validateFields()) {
                try {
                    const data = {
                        enabled: isOperationEnabled,
                        disabledMessage: disabledMessage
                    };

                    const response = await API.updateActivation(orgId, projectId, data);
                    if (response.code === 0) {
                        return data;
                    } else {
                        throw new Error(response.message || '更新失敗');
                    }
                } catch (error) {
                    throw error;
                }
            }
            return null;
        }
    }));

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress sx={{ color: '#CBA660' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', maxWidth: '600px' }}>
            <FormControlLabel
                control={
                    <CustomSwitch
                        checked={isOperationEnabled}
                        onChange={(e) => {
                            setIsOperationEnabled(e.target.checked);
                            setMessageError(false);
                        }}
                    />
                }
                label={isOperationEnabled ? "開啟" : "關閉"}
                sx={{width: 'fit-content'}}
            />

            <Box sx={{ mt: 4, textAlign: 'left' }}>
                <Typography variant="h6" sx={{ mb: 1 }}>關閉客服的固定回覆</Typography>
                <TextField
                    fullWidth
                    value={disabledMessage}
                    onChange={(e) => {
                        setDisabledMessage(e.target.value);
                        setMessageError(false);
                    }}
                    disabled={isOperationEnabled}
                    error={messageError}
                    helperText={messageError ? "請輸入內容" : ""}
                    sx={{
                        width: '100%',
                        maxWidth: '564px',
                        mb: 1,
                        '& .Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mb: 4, display: 'block' }}>
                    在關閉狀態使用者會收到此固定回覆訊息
                </Typography>
            </Box>
        </Box>
    );
});

export default OperationStatusTab;
import React from 'react';
import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Login/Login';
import AccountManagement from '../pages/AccountManagement/AccountManagement';
import KnowledgeBase from "../pages/KnowledgeBase/KnowledgeBase";
import QADetails from "../pages/QADetails/QADetails";
import QAUser from "../pages/QADetails/QAUser";
import AIServiceSetting from "../pages/AIServiceSetting/AIServiceSetting";
import UsageAndFees from "../pages/UsageAndFees/UsageAndFees";

const routes = (user) => [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/dashboard',
        element: user ? <Dashboard /> : <Navigate to="/login" replace />,
    },
    {
        path: '/account-management',
        element: user ? <AccountManagement /> : <Navigate to="/login" replace />,
    },
    {
        path: '/qa-details',
        element: user ? <QADetails /> : <Navigate to="/login" replace />,
    },
    {
        path: '/qa-user/:session_id', // 新添加的 QAUser 路由
        element: user ? <QAUser /> : <Navigate to="/login" replace />,
    },
    {
        path: '/ai-service-settings', // 新添加的 QAUser 路由
        element: user ? <AIServiceSetting /> : <Navigate to="/login" replace />,
    },
    {
        path: '/knowledge-base',
        element: user ? <KnowledgeBase /> : <Navigate to="/login" replace />,
    },
    {
        path: '/usage-and-fees',
        element: user ? <UsageAndFees /> : <Navigate to="/login" replace />,
    },
    {
        path: '/',
        element: <Navigate replace to={user ? "/dashboard" : "/login"} />,
    },
];

export default routes;
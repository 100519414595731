/**
 * Http method
 */
export const HttpMethodEnum = Object.freeze({
    /**
     * GET
     */
    GET: 'GET',
    /**
     * POST
     */
    POST: 'POST',
    /**
     * PUT
     */
    PUT: 'PUT',
    /**
     * PATCH
     */
    PATCH: 'PATCH',
    /**
     * DELETE
     */
    DELETE: 'DELETE',
})

/**
 * File Size Enum
 * @readonly
 * @enum {number}
 */
export const SizeEnum = Object.freeze({
    /**
     * 1 KB
     */
    KB: 1024,
    /**
     * 1 MB
     */
    MB: 1024 * 1024,
    /**
     * 1 GB
     */
    GB: 1024 * 1024 * 1024,
    /**
     * 1 TB
     */
    TB: 1024 * 1024 * 1024 * 1024,
});

/**
 * DialogTypeEnum
 * @readonly
 * @enum {string}
 */
export const DialogTypeEnum = Object.freeze({
    /**
     * Error
     */
    ERROR: 'error',

    /**
     * Warn
     */
    WARN: 'warn',

    /**
     * Success
     */
    SUCCESS: 'success',

    /**
     * Info
     */
    INFO: 'info',
});

/**
 * 模式列舉
 * @readonly
 * @enum {string}
 */
export const ModeEnum = Object.freeze({
    /**
     * 開發模式
     */
    DEVELOPMENT: 'development',

    /**
     * 測試模式
     */
    TESTING: 'testing',

    /**
     * 產品模式
     */
    PRODUCTION: 'production',
});
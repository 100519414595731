// src/contexts/ChatbotContext.js
import React, { createContext, useContext, useState } from 'react';

const ChatbotContext = createContext();

export const ChatbotProvider = ({ children }) => {
    const [welcomeMessage, setWelcomeMessage] = useState('您好! 歡迎光臨 義享時尚廣場 有什麼能為您服務?');

    return (
        <ChatbotContext.Provider value={{ welcomeMessage, setWelcomeMessage }}>
            {children}
        </ChatbotContext.Provider>
    );
};

export const useChatbot = () => {
    const context = useContext(ChatbotContext);
    if (!context) {
        throw new Error('useChatbot must be used within a ChatbotProvider');
    }
    return context;
};
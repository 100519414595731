import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {Add as AddIcon} from '@mui/icons-material';
import API from "../../api/v1";

const MAX_QUESTIONS = 10;
const MAX_CHARS = 100;

const PromptQuestionsTab = forwardRef(({ orgId, projectId }, ref) => {
    const [questions, setQuestions] = useState([]);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // 獲取初始數據
    useEffect(() => {
        fetchStarterPrompts();
    }, [orgId, projectId]);

    // 暴露獲取數據的方法給父組件
    useImperativeHandle(ref, () => ({
        getQuestionsData: () => {
            return {
                promptQuestions: questions.map((question, index) => ({
                    id: index + 1,
                    question: question
                }))
            };
        }
    }));

    const fetchStarterPrompts = async () => {
        try {
            setLoading(true);
            const response = await API.getStarterPrompts(orgId, projectId);
            if (response.code === 0 && response.data) {
                // 將每個問題轉換為陣列元素
                setQuestions(response.data.starterPrompts || []);
            } else {
                throw new Error(response.message || '獲取提示問題失敗');
            }
        } catch (err) {
            console.error('獲取提示問題失敗:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // 更新提示問題到後端
    const updateStarterPrompts = async (updatedQuestions) => {
        try {
            const response = await API.updateStarterPrompts(orgId, projectId, {
                starterPrompts: updatedQuestions
            });
            if (response.code !== 0) {
                throw new Error(response.message || '更新提示問題失敗');
            }
            return true;
        } catch (err) {
            console.error('更新提示問題失敗:', err);
            throw err;
        }
    };


    // 檢查是否超過字數限制
    const isExceedingLimit = newQuestion.length > MAX_CHARS;

    // 檢查是否達到問題數量上限
    const hasReachedQuestionLimit = questions.length >= MAX_QUESTIONS;

    // 本地處理新增問題
    const handleAddQuestion = () => {
        if (newQuestion.trim() && !isExceedingLimit && !hasReachedQuestionLimit) {
            setQuestions(prev => [...prev, newQuestion.trim()]);
            setNewQuestion('');
            setAddDialogOpen(false);
        }
    };

    // 本地處理刪除問題
    const handleDeleteQuestion = () => {
        if (deleteIndex !== null) {
            setQuestions(prev => prev.filter((_, index) => index !== deleteIndex));
            setDeleteIndex(null);
            setDeleteDialogOpen(false);
        }
    };

    // 驗證字段
    const validateFields = () => {
        // 至少要有一個提示問題
        return questions.length > 0;
    };

    // 處理輸入變更
    const handleInputChange = (e) => {
        setNewQuestion(e.target.value);
    };

    // 暴露方法給父組件
    useImperativeHandle(ref, () => ({
        validateFields,
        // 當點擊儲存並發布時執行的方法
        async getQuestionsData() {
            if (validateFields()) {
                try {
                    // 這裡執行 API 呼叫
                    const response = await API.updateStarterPrompts(orgId, projectId, {
                        starterPrompts: questions
                    });

                    if (response.code === 0) {
                        return {
                            starterPrompts: questions
                        };
                    } else {
                        throw new Error(response.message || '更新提示問題失敗');
                    }
                } catch (error) {
                    throw error;
                }
            }
            return null;
        }
    }));

    // 開啟刪除對話框
    const openDeleteDialog = (index) => {
        setDeleteIndex(index);
        setDeleteDialogOpen(true);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress sx={{ color: '#CBA660' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Tooltip title={hasReachedQuestionLimit ? "已達到提示問題數量上限 (10)" : ""}>
                    <span> {/* Wrapper span is needed for disabled Tooltip */}
                        <Button
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={() => setAddDialogOpen(true)}
                            disabled={hasReachedQuestionLimit}
                            sx={{
                                color: '#CBA660',
                                '&:hover': { color: '#B8935C' },
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(203, 166, 96, 0.12)'
                                }
                            }}
                        >
                            新增問題
                        </Button>
                    </span>
                </Tooltip>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'rgba(203, 166, 96, 0.08)' }}>
                            <TableCell sx={{width: 'calc((100% / 3) * 2)'}}>問題</TableCell>
                            <TableCell align="left">動作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question, index) => (
                            <TableRow key={index}>
                                <TableCell>{question}</TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => openDeleteDialog(index)}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#F44336',
                                            borderColor: '#F44336',
                                            color: '#FFFFFF',
                                            '&:hover': {
                                                backgroundColor: '#C62828',
                                            }
                                        }}
                                    >
                                        刪除
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* 新增問題對話框 */}
            <Dialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>新增提示問題</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value.slice(0, 100))}
                        inputProps={{ maxLength: 100 }}
                        sx={{ mt: 2 }}
                    />
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            textAlign: 'right',
                            mt: 1,
                            color: isExceedingLimit ? '#FF4D4F' : 'text.secondary'
                        }}
                    >
                        {isExceedingLimit ? (
                            '字數已超過上限'
                        ) : (
                            `${newQuestion.length}/${MAX_CHARS}`
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setAddDialogOpen(false);
                            setNewQuestion('');
                        }}
                        sx={{ color: 'text.primary' }}
                    >
                        取消
                    </Button>
                    <Button
                        onClick={handleAddQuestion}
                        disabled={isExceedingLimit || !newQuestion.trim()}
                        sx={{
                            color: '#CBA660',
                            '&:hover': { color: '#B8935C' }
                        }}
                        variant="text"
                    >
                        新增
                    </Button>
                </DialogActions>
            </Dialog>

            {/* 刪除確認對話框 */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>刪除提示問題</DialogTitle>
                <DialogContent>
                    <Typography>
                        確定要刪除這個提示問題嗎？
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        sx={{ color: 'text.primary' }}
                    >
                        取消
                    </Button>
                    <Button
                        onClick={handleDeleteQuestion}
                        sx={{
                            color: '#CBA660',
                            '&:hover': { color: '#B8935C' }
                        }}
                        variant="text"
                    >
                        刪除
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
});

export default PromptQuestionsTab;
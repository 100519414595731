import { useCallback, useEffect, useRef, useState } from 'react'

export const BubbleChat = (props) => {
  const ref = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    ;(async () => {
      await import('flowise-embed/dist/web')
      setIsInitialized(true)
    })()
    return () => {
      if (ref.current) {
        ref.current.remove()
      }
    }
  }, [])

  const attachBubbleToDom = useCallback((props) => {
    const bubbleElement = document.createElement('flowise-chatbot')
    ref.current = bubbleElement
    injectPropsToElement(ref.current, props)
    document.body.append(ref.current)
  }, [])

  useEffect(() => {
    if (!isInitialized) return
    if (!ref.current) attachBubbleToDom(props)
    injectPropsToElement(ref.current, props)
  }, [attachBubbleToDom, isInitialized, props])

  const injectPropsToElement = (element, props) => {
    Object.assign(element, props)
  }

  return null
}
import Layout from "../..//Layout/Layout";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import API from "../../api/v1";
import {validPassword} from "../../utils/utils";
import {useNavigate} from "react-router-dom";
import {remLocalStorage} from "../../utils/localStorage";
import useUserInfo from "../../hooks/userProfile";

const AccountManagement = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const {userInfo} = useUserInfo()

    // 用戶名編輯相關狀態
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [nicknameSuccess, setNicknameSuccess] = useState(false);
    const [editNickname, setEditNickname] = useState('');
    const [editError, setEditError] = useState('');
    const [displayNickname, setDisplayNickname] = useState('');
    const [editLoading, setEditLoading] = useState(false);

    // 監聽 userInfo 變化，更新顯示的用戶名
    useEffect(() => {
        if (userInfo?.nickName) {
            setDisplayNickname(userInfo.nickName);
        }
    }, [userInfo]);

    const handleSubmit = async () => {
        if (!validPassword(oldPassword)) {
            console.log('是舊密碼' + oldPassword)
            setError('密碼長度不得低於8個字');
            return;
        }

        if (!validPassword(newPassword)) {
            console.log('是新密碼' + newPassword)
            setError('密碼長度不得低於8個字');
            return;
        }

        if (newPassword !== confirmPassword) {
            console.log('是確認密碼' + confirmPassword)
            setError('新密碼和確認密碼不匹配');
            return;
        }
        try {
            const response = await API.modifyPassword({
                oldPassword,
                newPassword
            });

            if (response.code === 0) {
                setSuccess(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                remLocalStorage('token')
                remLocalStorage('userInfo')
                navigate('/login');
            } else {
                setError('更新密碼失敗');
            }

        } catch (err) {
            setError('更新密碼時發生錯誤');
            console.error('更新密碼錯誤:', err);
        }
    }

    // 打開編輯用戶名對話框
    const handleOpenEditDialog = () => {
        setEditNickname(displayNickname || '');
        setEditError('');
        setIsEditDialogOpen(true);
    };

    // 關閉編輯用戶名對話框
    const handleCloseEditDialog = () => {
        setEditNickname('');
        setEditError('');
        setIsEditDialogOpen(false);
    };

    // 儲存用戶名
    const handleSaveNickname = async () => {
        if (!editNickname.trim()) {
            setEditError('用戶名不能為空');
            return;
        }

        setEditLoading(true);
        setEditError('');

        try {
            const response = await API.updateProfile({
                nickName: editNickname.trim()
            });

            if (response.code === 0) {
                // 更新本地狀態
                setDisplayNickname(editNickname.trim());

                // 更新 localStorage
                const userInfoStr = localStorage.getItem('userInfo');
                if (userInfoStr) {
                    const storedUserInfo = JSON.parse(userInfoStr);
                    storedUserInfo.nickName = editNickname.trim();
                    localStorage.setItem('userInfo', JSON.stringify(storedUserInfo));
                }

                setNicknameSuccess(true);
                handleCloseEditDialog();
            } else {
                setEditError(response.message || '更新失敗');
            }
        } catch (err) {
            console.error('更新用戶名錯誤:', err);
            setEditError('更新用戶名時發生錯誤');
        } finally {
            setEditLoading(false);
        }
    };

    return (
        <Layout>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, width: "366px"}}>
                <Typography variant={'h4'} sx={{textAlign: 'left', mb: 5, mt: 2}}>帳戶管理</Typography>

                <Box sx={{ mb: 8 }}>
                    <Typography textAlign="left" component="p" variant="h5" sx={{ mb: 2 }}>帳戶</Typography>
                    <Typography textAlign="left" color={'rgba(0,0,0,0.6)'} component="p" variant="body2">用戶名</Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', mb: 4}}>
                        <Typography textAlign="left" variant="body1">{displayNickname}</Typography>
                        <Button
                            onClick={handleOpenEditDialog}
                            variant="outlined"
                            size="small"
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderColor: '#CBA660',
                                color: '#CBA660',
                                whiteSpace: 'nowrap',
                                flexShrink: 0,
                                ml: 1,
                                '&:hover': { backgroundColor: '#CBA660', color: '#FFFFFF', borderColor: '#CBA660', },
                            }}
                        >
                            編輯
                        </Button>
                    </Box>
                    <Typography textAlign="left" color={'rgba(0,0,0,0.6)'} component="p" variant="body2">帳號</Typography>
                    <Typography textAlign="left" variant="body1">{userInfo?.email}</Typography>
                </Box>

                <Box sx={{ mb: 4 }} component="form">
                    <Typography textAlign="left" variant="h6" sx={{ mb: 2 }}>重設密碼</Typography>
                    <TextField
                        fullWidth
                        label="原密碼"
                        type="password"
                        variant="outlined"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                        inputProps={{
                            autoComplete: "current-password"
                        }}
                    />
                    <TextField
                        fullWidth
                        label="新密碼"
                        type="password"
                        variant="outlined"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                        inputProps={{
                            autoComplete: "current-password"
                        }}
                    />
                    <TextField
                        fullWidth
                        label="確認密碼"
                        type="password"
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                        inputProps={{
                            autoComplete: "current-password"
                        }}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#CBA660',
                            '&:hover': { backgroundColor: '#B8935C' },
                            height: '48px'
                        }}
                        onClick={() => {handleSubmit()}}
                    >
                        變更密碼
                    </Button>

                    {/* 編輯用戶名對話框 */}
                    <Dialog open={isEditDialogOpen} onClose={handleCloseEditDialog} maxWidth="sm" fullWidth>
                        <DialogTitle>修改用戶名</DialogTitle>
                        <DialogContent>
                            {editError && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {editError}
                                </Alert>
                            )}
                            <TextField
                                autoFocus
                                margin="dense"
                                label="用戶名"
                                type="text"
                                fullWidth
                                value={editNickname}
                                onChange={(e) => setEditNickname(e.target.value)}
                                sx={{ mt: 2 }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleCloseEditDialog}
                                variant="outlined"
                                sx={{
                                    borderColor: '#CBA660',
                                    color: '#CBA660',
                                    '&:hover': {
                                        borderColor: '#B8935C',
                                        backgroundColor: 'rgba(203, 166, 96, 0.04)'
                                    }
                                }}
                            >
                                取消
                            </Button>
                            <Button
                                onClick={handleSaveNickname}
                                disabled={editLoading}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#CBA660',
                                    '&:hover': { backgroundColor: '#B8935C' }
                                }}
                            >
                                儲存
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                            密碼更新成功！
                        </Alert>
                    </Snackbar>
                    <Snackbar open={nicknameSuccess} autoHideDuration={6000} onClose={() => setNicknameSuccess(false)}>
                        <Alert onClose={() => setNicknameSuccess(false)} severity="success" sx={{ width: '100%' }}>
                            用戶名修改成功！
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Layout>
    )
}

export default AccountManagement;
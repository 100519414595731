// 導航欄組件
import React, {useCallback, useState} from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Tooltip,
    MenuItem, Card, CardMedia, Divider
} from '@mui/material';
import Logo from '../../assets/images/logo.svg'
import {useNavigate} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase";
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import {remLocalStorage} from "../../utils/localStorage";


/**
 * 簡化的響應式導航欄組件
 * @returns {JSX.Element} 渲染的導航欄組件
 */
const Navbar = ({userInfo}) => {

    // 用戶菜單狀態
    const [anchorElUser, setAnchorElUser] = useState(null);

    const navigate = useNavigate();

    /**
     * 打開用戶菜單
     * @param {Event} event 觸發事件
     */
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    /**
     * 關閉用戶菜單
     */
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuItemClick = useCallback((action) => {
        setAnchorElUser(null);
        switch (action) {
            case 'account':
                navigate('/account-management');
                break;
            case 'logout':
                signOut(auth).then(() => {
                    // 清除 localStorage 中的用戶資訊
                    remLocalStorage('userInfo');
                    remLocalStorage('token');
                    // 如果有其他需要清除的項目，也可以在這裡添加
                    // 例如：localStorage.clear(); 會清除所有本地存儲
                    // 導航到登入頁面
                    navigate('/login');
                }).catch((error) => {
                    console.error('Logout error:', error);
                });
                break;
            default:
                break;
        }
    }, [navigate]);

    return (
        <AppBar position="static" elevation={5} sx={{backgroundColor: 'white', height: '56px', zIndex: 2}}>
            <Container maxWidth="xl" sx={{height: '100%'}}>
                <Toolbar disableGutters sx={{height: '100%', minHeight: 'inherit !important'}}>
                    {/* Logo 部分 */}
                    <Card
                        elevation={0}
                        sx={{backgroundColor: 'transparent'}}
                    >
                        <CardMedia component="img"
                                   image={Logo}
                                   sx={{width: '172px'}}/>
                    </Card>
                    {/* 填充空間 */}
                    <Box sx={{flexGrow: 1}}/>

                    {/* 用戶頭像和下拉菜單 */}
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings" placement={"bottom-end"}>
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0, mr: 1}}>
                                    <Avatar>{(userInfo?.nickName || userInfo?.email || '訪客').charAt(0).toUpperCase()}</Avatar>
                                </IconButton>
                                <Typography variant={'body2'} color={'#CBA660'}>{userInfo?.nickName || userInfo?.email || '訪客'}</Typography>
                            </Box>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <Box sx={{ p: 2, width: 220 }}>
                                <Typography variant="subtitle1">{userInfo?.nickName || userInfo?.email || '訪客'}</Typography>
                                <Typography variant="body2" color="text.secondary">{userInfo?.email || 'user@example.com'}</Typography>
                            </Box>
                            <Divider />
                            <MenuItem onClick={() => handleMenuItemClick('account')}>
                                <AccountCircle sx={{ mr: 2 }} />
                                <Typography textAlign="center">帳戶管理</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => handleMenuItemClick('logout')}>
                                <ExitToApp sx={{ mr: 2 }} />
                                <Typography textAlign="center">登出</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
// src/services/tokenManagement.js
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getLocalStorage, setLocalStorage, remLocalStorage } from '../utils/localStorage';
import API from '../api/v1/index';
import {signOut} from "firebase/auth";
import {auth} from "../firebase";
import {getNavigate} from "./navigationService";

// 擴展 dayjs 以支持 UTC 和時區功能
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Token 管理服務類
 * 負責處理 token 的存儲、刷新和驗證
 */
class TokenManagementService {
    constructor() {
        this.refreshTimeout = null; // 用於存儲刷新 token 的 timeout
        this.checkInterval = null; // 用於存儲定期檢查 token 的 interval
        this.isRefreshing = false; // 標記是否正在刷新 token
        this.refreshSubscribers = []; // 存儲等待 token 刷新的訂閱者
    }
    /**
     * 初始化服務
     * 在應用啟動時調用，檢查並設置現有的 token
     */
    initialize() {
        const token = getLocalStorage('token');
        const expireTime = getLocalStorage('expire');
        if (token && expireTime) {
            this.scheduleTokenRefresh(expireTime);
            this.startPeriodicCheck();
        }
    }

    /**
     * 設置新的 token 和過期時間
     * @param {string} token - 新的 token
     * @param {string} expireTime - token 的過期時間
     */
    setToken(token, expireTime) {
        setLocalStorage('token', token);
        setLocalStorage('expire', expireTime);
        this.scheduleTokenRefresh(expireTime);
        this.startPeriodicCheck();
    }

    /**
     * 安排 token 刷新
     * @param {string} expireTime - token 的過期時間
     */
    scheduleTokenRefresh(expireTime) {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        const expirationTime = dayjs(expireTime).tz("Asia/Taipei");
        const currentTime = dayjs().tz("Asia/Taipei");
        const timeUntilExpire = expirationTime.diff(currentTime, 'millisecond') - 60000; // 提前5分鐘

        if (timeUntilExpire > 0) {
            this.refreshTimeout = setTimeout(() => this.refreshToken(), timeUntilExpire);
        } else {
            this.refreshToken();
        }
    }

    /**
     * 開始定期檢查 token 有效性
     */
    startPeriodicCheck() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
        }
        // 每10分鐘檢查一次
        this.checkInterval = setInterval(() => this.checkTokenValidity(), 300000);
    }

    /**
     * 檢查 token 的有效性
     */
    checkTokenValidity() {
        const token = getLocalStorage('token');
        const expireTime = getLocalStorage('expire');
        if (!token || !expireTime) {
            this.handleTokenInvalid();
            return;
        }

        const expirationTime = dayjs(expireTime).tz("Asia/Taipei");
        const currentTime = dayjs().tz("Asia/Taipei");
        if (currentTime.isAfter(expirationTime)) {
            this.handleTokenInvalid();
        } else if (expirationTime.diff(currentTime, 'minute') <= 10) {
            this.refreshToken();
        }
    }

    /**
     * 處理 token 無效的情況
     */
    handleTokenInvalid() {
        const navigate = getNavigate();
        signOut(auth).then(() => {
            // 清除 localStorage 中的用戶資訊
            remLocalStorage('userInfo');
            remLocalStorage('token');
            // 如果有其他需要清除的項目，也可以在這裡添加
            // 例如：localStorage.clear(); 會清除所有本地存儲
            // 導航到登入頁面
            navigate('/login');
        }).catch((error) => {
            console.error('Logout error:', error);
        });
        // 觸發自定義事件，通知應用 token 已失效
        window.dispatchEvent(new CustomEvent('tokenInvalid'));
    }

    /**
     * 刷新 token
     * @returns {Promise<string|null>} 新的 token 或 null（如果刷新失敗）
     */
    async refreshToken() {
        if (this.isRefreshing) {
            return new Promise((resolve) => {
                this.refreshSubscribers.push(resolve);
            });
        }

        this.isRefreshing = true;

        try {
            const response = await API.refreshToken();
            if (response.code === 0 && response.data) {
                this.setToken(response.data.token, response.data.expire);
                this.onRefreshSuccess(response.data.token);
                return response.data.token;
                console.log('Token refresh complete');
            } else {
                this.onRefreshFailure(new Error(response.message || 'Token refresh failed'));

                return null;
            }
        } catch (error) {
            this.onRefreshFailure(error);
            return null;
        }
    }

    /**
     * 處理 token 刷新成功的情況
     * @param {string} newToken - 新的 token
     */
    onRefreshSuccess(newToken) {
        this.refreshSubscribers.forEach((callback) => callback(newToken));
        this.refreshSubscribers = [];
        this.isRefreshing = false;
    }

    /**
     * 處理 token 刷新失敗的情況
     * @param {Error} error - 刷新過程中遇到的錯誤
     */
    onRefreshFailure(error) {
        this.refreshSubscribers.forEach((callback) => callback(null));
        this.refreshSubscribers = [];
        this.isRefreshing = false;
        this.handleTokenInvalid();
        console.error('Token refresh failed:', error);
    }
}

const tokenManagementService = new TokenManagementService();
export default tokenManagementService;
import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Switch,
    FormControlLabel,
    Box,
    Typography, styled, Tooltip, IconButton, Snackbar, Alert,
} from '@mui/material';
import {Add, InfoOutlined} from '@mui/icons-material';
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import useUserInfo from "../../hooks/userProfile";
import API from "../../api/v1";
import {formatDate} from "../../utils/dateUtils";

const SupplementaryKnowledgeTable = ({ onRefreshData }) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [data, setData] = useState([]);
    const {userInfo} = useUserInfo();

    // 限制事件最大數量
    const MAX_EVENTS = 20;
    const KEYWORD_MAX_LENGTH = 200;
    const CONTENT_MAX_LENGTH = 1000;

    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        keyword: '',
        response: '',
        reference: '',
        isEnabled: true,
        isActive: true
    });

    const fetchData = async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return;
        }

        setLoading(true);
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const knowbaseId = userInfo.organizations[0].projects[0].knowbaseId;

            const response = await API.getKnowbaseEvents({ orgId, projectId, knowbaseId });
            if (response.code === 0 && response.data) {
                setData(response.data.events);
            } else {
                throw new Error(response.message || '獲取數據失敗');
            }
        } catch (err) {
            setError(err.message);
            setSnackbar({ open: true, message: err.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = () => {
        if (data.length >= MAX_EVENTS) {
            setSnackbar({
                open: true,
                message: `補充知識最多只能新增 ${MAX_EVENTS} 筆`,
                severity: 'warning'
            });
            return;
        }

        setFormData({
            startDate: dayjs().hour(0).minute(1),
            endDate: dayjs().hour(23).minute(59),
            keyword: '',
            response: '',
            reference: '',
            isActive: true,
            isEnabled: true
        });
        setOpenAddDialog(true);
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setFormData({
            startDate: dayjs(item.start_time, 'YYYY/MM/DD HH:mm'),
            endDate: dayjs(item.end_time, 'YYYY/MM/DD HH:mm'),
            keyword: item.keywords,
            response: item.content,
            reference: item.reference_url,
            isActive: item.is_active,
            isEnabled: item.is_enabled,
        });
        setOpenEditDialog(true);
    };

    const handleSubmit = async () => {
        const orgId = userInfo.organizations[0].id;
        const projectId = userInfo.organizations[0].projects[0].id;
        const knowbaseId = userInfo.organizations[0].projects[0].knowbaseId;

        const submitData = {
            is_enabled: formData.isEnabled,
            keywords: formData.keyword,
            reference_url: formData.reference,
            start_time: formData.startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
            end_time: formData.endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
            content: formData.response
        };

        try {
            let response;
            if (openAddDialog) {
                response = await API.createKnowbaseEvent(
                    { orgId, projectId, knowbaseId },
                    submitData
                );
            } else {
                response = await API.updateKnowbaseEvent(
                    { orgId, projectId, knowbaseId, eventId: selectedItem.id },
                    submitData
                );
            }

            if (response.code === 0) {
                setSnackbar({
                    open: true,
                    message: openAddDialog ? '新增成功' : '更新成功',
                    severity: 'success'
                });
                await fetchData();
                handleCloseDialogs();
                onRefreshData();
            } else {
                throw new Error(response.message || (openAddDialog ? '新增失敗' : '更新失敗'));
            }
        } catch (err) {
            setSnackbar({ open: true, message: err.message, severity: 'error' });
        }
    };

    const handleDeleteDialog = (item) => {
        setSelectedItem(item);
        setOpenDeleteDialog(true);
    };

    const handleDelete = async () => {
        if (!selectedItem) return;

        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const knowbaseId = userInfo.organizations[0].projects[0].knowbaseId;

            const response = await API.deleteKnowbaseEvent({
                orgId,
                projectId,
                knowbaseId,
                eventId: selectedItem.id
            });

            if (response.code === 0) {
                setSnackbar({ open: true, message: '刪除成功', severity: 'success' });
                await fetchData();
                onRefreshData();
            } else {
                throw new Error(response.message || '刪除失敗');
            }
        } catch (err) {
            setSnackbar({ open: true, message: err.message, severity: 'error' });
        } finally {
            handleCloseDialogs();
        }
    };

    const handleCloseDialogs = () => {
        setOpenAddDialog(false);
        setOpenEditDialog(false);
        setOpenDeleteDialog(false);
        setSelectedItem(null);
    };

    const handleDateTimeChange = (type, newValue) => {
        const adjustedTime = type === 'start'
            ? newValue.minute(1)
            : newValue.minute(59);

        setFormData(prev => ({
            ...prev,
            [type === 'start' ? 'startDate' : 'endDate']: adjustedTime
        }));
    };

    const CustomSwitch = styled(Switch)({
        '& .MuiSwitch-switchBase': {
            transition: 'all 0.2s ease',
            '&.Mui-checked': {
                color: '#CBA660',
                transform: 'translateX(20px)',
                '&:hover': {
                    backgroundColor: 'rgba(203, 166, 96, 0.08)'
                },
                '& + .MuiSwitch-track': {
                    backgroundColor: '#CBA660 !important',
                    opacity: 0.5,
                    border: 0
                }
            },
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA !important',
            opacity: 1,
            transition: 'background-color 0.2s ease'
        },
        '& .MuiSwitch-thumb': {
            transition: 'all 0.2s ease'
        }
    });

    useEffect(() => {
        fetchData();
    }, [userInfo]);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                    startIcon={<Add />}
                    onClick={handleAdd}
                    sx={{
                        color: '#CBA660',
                        '&:hover': { color: '#B8935C' }
                    }}
                >
                    新增
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ width: '956px' }}>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'rgba(203, 166, 96, 0.08)' }}>
                            <TableCell width={120} align="center">狀態</TableCell>
                            <TableCell align="center">開始時間</TableCell>
                            <TableCell align="center">結束時間</TableCell>
                            <TableCell align="center">關鍵字</TableCell>
                            <TableCell align="center">內文</TableCell>
                            <TableCell align="center">動作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                }}
                            >
                                <TableCell width={120} align="center">
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, width: '100%' }}>
                                        <Box
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                borderRadius: '50%',
                                                backgroundColor: row.is_active ? '#64DD17' : '#D9D9D9'
                                            }}
                                        />
                                        {row.is_active ? '有效' : '無效'}
                                    </Box>
                                </TableCell>
                                <TableCell width={160} align="center">{formatDate(row.start_time)}</TableCell>
                                <TableCell width={160} align="center">{formatDate(row.end_time)}</TableCell>
                                <TableCell width={160} align="center">
                                    <typography sx={{width: '100%'}}>{row.keywords}</typography>
                                </TableCell>
                                <TableCell width={200} align="center">
                                    <typography sx={{width: '100%'}}>{row.content}</typography>
                                </TableCell>
                                <TableCell width={156} align="center">
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => handleEdit(row)}
                                        sx={{
                                            minWidth: '47px',
                                            mr: 1,
                                            backgroundColor: '#CBA660',
                                            '&:hover': { backgroundColor: '#B8935C' }
                                        }}
                                    >
                                        編輯
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => handleDeleteDialog(row)}
                                        sx={{
                                            minWidth: '47px',
                                            backgroundColor: '#F44336',
                                            '&:hover': { backgroundColor: '#C62828' }
                                        }}
                                    >
                                        刪除
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* 新增/編輯對話框 */}
            <Dialog
                open={openAddDialog || openEditDialog}
                onClose={handleCloseDialogs}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{padding: '48px 40px 32px'}}>
                    {openAddDialog ? '新增活動資訊' : '編輯活動資訊'}
                </DialogTitle>
                <DialogContent sx={{padding: '0 40px 32px'}}>
                    <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={formData.isEnabled}
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        isEnabled: e.target.checked
                                    })}
                                />
                            }
                            label={formData.isEnabled ? '開啟' : '關閉'}
                            sx={{width: 'fit-content', mb: 4}}
                        />
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="開始時間"
                                    value={formData.startDate}
                                    onChange={(newValue) => handleDateTimeChange('start', newValue)}
                                    views={['year', 'month', 'day', 'hours']}
                                    format="YYYY/MM/DD HH:mm"
                                    ampm={false}
                                    sx={{ width: '100%' }}
                                />
                                <p> - </p>
                                <DateTimePicker
                                    label="結束時間"
                                    value={formData.endDate}
                                    onChange={(newValue) => handleDateTimeChange('end', newValue)}
                                    views={['year', 'month', 'day', 'hours']}
                                    format="YYYY/MM/DD HH:mm"
                                    ampm={false}
                                    sx={{ width: '100%' }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <TextField
                            fullWidth
                            label="關鍵字"
                            multiline
                            value={formData.keyword}
                            onChange={(e) => {
                                if (e.target.value.length <= KEYWORD_MAX_LENGTH) {
                                    setFormData({
                                        ...formData,
                                        keyword: e.target.value
                                    });
                                }
                            }}
                            sx={{ mb: 2 }}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title="使用者會詢問到與此項之是相關的關鍵字或問題，可使用標點符號區隔，例如/，也可以換行，上限200字。">
                                        <IconButton size="small">
                                            <InfoOutlined fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            helperText={`${formData.keyword.length}/${KEYWORD_MAX_LENGTH}`}
                        />
                        <TextField
                            fullWidth
                            label="回答內文"
                            multiline
                            value={formData.response}
                            onChange={(e) => {
                                if (e.target.value.length <= CONTENT_MAX_LENGTH) {
                                    setFormData({
                                        ...formData,
                                        response: e.target.value
                                    });
                                }
                            }}
                            sx={{ mb: 2 }}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title="Chatbot回答的內容參考，可盡量寫的詳細完整，AI會根據內容做理解後，彈性回覆給使用者。上限 1000 字。">
                                        <IconButton size="small">
                                            <InfoOutlined fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            helperText={`${formData.response.length}/${CONTENT_MAX_LENGTH}`}
                        />
                        <TextField
                            fullWidth
                            label="參考網址"
                            value={formData.reference}
                            onChange={(e) => setFormData({
                                ...formData,
                                reference: e.target.value
                            })}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title="若回答內文有提到網頁或網站，請寫下關鍵字後帶出網址參數。可多筆，例如：本期DM：http://www.ffwfee..... 官方網站：http://www.eerfg....。">
                                        <IconButton size="small">
                                            <InfoOutlined fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: '0 40px 48px'}}>
                    <Button
                        variant="outlined"
                        onClick={handleCloseDialogs}
                        sx={{
                            color: '#CBA660',
                            borderColor: '#CBA660',
                            '&:hover': { color: '#B8935C', borderColor: '#B8935C' }
                        }}
                    >
                        取消
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{
                            backgroundColor: '#CBA660',
                            '&:hover': { backgroundColor: '#B8935C' }
                        }}
                    >
                        儲存
                    </Button>
                </DialogActions>
            </Dialog>

            {/* 刪除確認對話框 */}
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDialogs}
            >
                <DialogTitle>確認刪除</DialogTitle>
                <DialogContent>
                    <Typography>
                        確定要刪除這筆資料嗎？此動作無法復原。
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialogs}
                        sx={{
                            color: '#CBA660',
                            '&:hover': { color: '#B8935C'}
                        }}
                    >
                        取消
                    </Button>
                    <Button
                        onClick={handleDelete}
                        sx={{
                            color: '#CBA660',
                            '&:hover': { color: '#B8935C'}
                        }}
                    >
                        刪除
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SupplementaryKnowledgeTable;
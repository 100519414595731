// src/hooks/useUserProfile.js

import {useState, useEffect} from 'react';
import API from '../api/v1/index';
import {getLocalStorage, remLocalStorage, setLocalStorage} from "../utils/localStorage";
import {signOut} from "firebase/auth";
import {auth} from "../firebase";
import {useNavigate} from "react-router-dom"; // 假設這是您的 API 客戶端


/**
 * @typedef {Object} UserInfo
 * @property {string} id - 用戶 ID
 * @property {string} nickName - 用戶暱稱
 * @property {string} email - 用戶電子郵件
 * @property {number} orgCount - 組織數量
 * @property {Array<Organization>} organizations - 用戶所屬的組織列表
 */

/**
 * @typedef {Object} Organization
 * @property {string} id - 組織 ID
 * @property {string} name - 組織名稱
 * @property {string} displayName - 組織顯示名稱
 * @property {string} createdAt - 創建時間
 * @property {string} updatedAt - 更新時間
 * @property {number} projectCount - 專案數量
 * @property {Array<Project>} projects - 組織內的專案列表
 */

/**
 * @typedef {Object} Project
 * @property {string} id - 專案 ID
 * @property {string} knowbaseId - 知識庫 ID
 * @property {string} scenario - 場景描述
 * @property {string} name - 專案名稱
 * @property {string} displayName - 專案顯示名稱
 * @property {string} createdAt - 創建時間
 * @property {string} updatedAt - 更新時間
 */

/**
 * 用戶資料 Hook
 * @returns {{userInfo: UserInfo|null, loading: boolean, error: Error|null, getUserInfo: Function}}
 */
const useUserInfo = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            setLoading(true);
            try {
                // 首先嘗試從 localStorage 獲取
                // const storedInfo = getLocalStorage('userInfo');
                // if (storedInfo) {
                //     setUserInfo(JSON.parse(storedInfo));
                //     setLoading(false);
                //     return;
                // }

                // 如果 localStorage 中沒有，則從 API 獲取
                const response = await API.getProfileInfo();
                if (response.code === 0 && response.data) {
                    setUserInfo(response.data);
                    setLocalStorage('userInfo', JSON.stringify(response.data));
                } else {
                    throw new Error(response.message || '獲取用戶資訊失敗');

                }
            } catch (err) {
                console.error('獲取用戶資訊錯誤:', err);
                signOut(auth).then(() => {
                    // 清除 localStorage 中的用戶資訊
                    remLocalStorage('userInfo');
                    remLocalStorage('token');
                    // 如果有其他需要清除的項目，也可以在這裡添加
                    // 例如：localStorage.clear(); 會清除所有本地存儲

                    // 導航到登入頁面
                    navigate('/login');
                }).catch((error) => {
                    console.error('Logout error:', error);
                });
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserInfo().then(r => {});
    }, []);

    return { userInfo, loading, error };
};

export default useUserInfo;
// Dashboard頁面組件
import React, {useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {
    Box,
    Typography,
    Button,
    Card,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack, Grid, useMediaQuery,
} from '@mui/material';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {LineChart} from '@mui/x-charts/LineChart';
import Layout from "../../Layout/Layout";
import useUserInfo from "../../hooks/userProfile";
import { useTheme } from '@mui/material/styles';
import API from "../../api/v1";

/**
 * Dashboard主頁面組件
 * @returns {JSX.Element} 渲染的Dashboard頁面
 */
const Dashboard = () => {
    // 狀態定義
    const [dateRange, setDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]); // 日期範圍，預設為最近30天
    const [temporalUnit, setTemporalUnit] = useState('day'); // 時間單位，預設為天
    const [periodSelect, setPeriodSelect] = useState(30); // 預定義時間範圍選擇，預設為30天
    const [dashboardData, setDashboardData] = useState(null); // 儀表板概覽數據
    const [chartModelData, setChartModelData] = useState([]); // 費用趨勢圖表數據
    const [chartTracesData, setChartTracesData] = useState([]); // 問題數量趨勢圖表數據
    const [loading, setLoading] = useState(true); // 加載狀態
    const [error, setError] = useState(null); // 錯誤狀態
    const { userInfo, loading: userLoading, error: userError } = useUserInfo();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    // 根據日期範圍確定適當的時間單位
    const determineTemporalUnit = useCallback((start, end) => {
        const diffDays = end.diff(start, 'day');
        if (diffDays <= 1) return 'hour';
        if (diffDays <= 30) return 'day';
        if (diffDays <= 180) return 'week';
        return 'month';
    }, []);

    // 更新日期範圍和時間單位
    const updateDateRangeAndUnit = useCallback((start, end) => {
        setDateRange([start, end]);
        setTemporalUnit(determineTemporalUnit(start, end));
    }, [determineTemporalUnit]);

    // 處理日期範圍選擇器變更
    const handleDateRangeChange = (newDateRange) => {
        if (newDateRange[0] && newDateRange[1]) {
            updateDateRangeAndUnit(newDateRange[0], newDateRange[1]);
        }
    };

    // 處理預定義時間範圍選擇變更
    const handlePeriodSelectChange = (event) => {
        const days = event.target.value;
        setPeriodSelect(days);
        const end = dayjs();
        const start = end.subtract(days, 'day');
        console.log('start:' + start + '-' + 'end:' + end);
        updateDateRangeAndUnit(start, end);
    };

    // 獲取儀表板數據的主要函數
    const fetchDashboardData = useCallback(async () => {
        if (!userInfo?.organizations?.[0]?.id || !userInfo?.organizations?.[0]?.projects?.[0]?.id) {
            return; // 如果沒有必要的用戶信息，則不執行
        }
        setLoading(true);
        try {
            const orgId = userInfo.organizations[0].id;
            const projectId = userInfo.organizations[0].projects[0].id;
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // 獲取儀表板概覽數據
            const overviewResponse = await API.getDashboardOverview(orgId, projectId, timezone);
            if (overviewResponse.code === 0) {
                setDashboardData(overviewResponse.data);
            } else {
                throw new Error(overviewResponse.message || '獲取儀表板數據失敗');
            }

            const [startDate, endDate] = dateRange;
            const params = {
                from_time: startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                to_time: endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                temporal_unit: temporalUnit
            };

            // 獲取費用趨勢圖表數據
            const modelResponse = await API.getChartModels(orgId, projectId, params);
            if (modelResponse.code === 0 && modelResponse.data) {
                setChartModelData(modelResponse.data);
            } else {
                console.error('Unexpected chart model data structure:', modelResponse);
                setChartModelData({ count: 0, json: [] });
            }

            // 獲取問題數量趨勢圖表數據
            const tracesResponse = await API.getChartTraces(orgId, projectId, params);
            if (tracesResponse.code === 0 && tracesResponse.data) {
                setChartTracesData(tracesResponse.data);
            } else {
                console.error('Unexpected chart traces data structure:', tracesResponse);
                setChartTracesData({ count: 0, json: [] });
            }
        } catch (err) {
            console.error('獲取數據錯誤:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [userInfo, dateRange, temporalUnit]);

    useEffect(() => {
        if (!userLoading && userInfo) {
            fetchDashboardData().then(r => {});
        }
    }, [userInfo, userLoading, fetchDashboardData]);

    // 處理圖表數據的輔助函數
    const processChartData = (data, isModelData = false) => {
        if (!Array.isArray(data?.json)) {
            return { xAxisData: [], seriesData: [] };
        }
        const validData = data.json.filter(item =>
            item &&
            typeof item.timestamp === 'string' &&
            !isNaN(new Date(item.timestamp).getTime()) &&
            (isModelData
                    ? typeof item.sumCalculatedTotalCost === 'number' && !isNaN(item.sumCalculatedTotalCost)
                    : typeof item.countTraceId === 'number' && !isNaN(item.countTraceId)
            )
        );
        return {
            xAxisData: validData.map(item => new Date(item.timestamp)),
            seriesData: isModelData
                ? validData.map(item => item.sumCalculatedTotalCost)
                : validData.map(item => item.countTraceId)
        };
    };

    // 處理圖表數據
    const tracesChartData = processChartData(chartTracesData);
    const modelChartData = processChartData(chartModelData, true);


    if (userError || error) {
        return (
            <Layout>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Typography color="error">錯誤: {userError || error}</Typography>
                </Box>
            </Layout>
        );
    }

    // if (!dashboardData) {
    //     return (
    //         <Layout>
    //             <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //                 <Typography>無數據可顯示</Typography>
    //             </Box>
    //         </Layout>
    //     );
    // }


    return (
        <Layout>
            {/*右側內容筐*/}
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, mb: 10, width: {sm: `calc(100% - 256px)`}}}>
                <Typography variant={'h4'} sx={{textAlign: 'left', mb: 5, mt: 2}}>{userInfo?.nickName || '用戶'}</Typography>

                {/*數據小卡*/}
                <Box mb={8} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start'
                }}>
                    {/*本日發問客戶數*/}
                    {/*<Card sx={{*/}
                    {/*    width: '227px',*/}
                    {/*    height: '168px',*/}
                    {/*    mr: 2,*/}
                    {/*    display: 'flex',*/}
                    {/*    flexDirection: 'column',*/}
                    {/*    alignItems: 'flex-start',*/}
                    {/*    padding: '32px 16px',*/}
                    {/*    boxSizing: 'border-box'*/}
                    {/*}}>*/}
                    {/*    <Typography variant={'subtitle2'} mb={1} color={'#CBA660'}>2024/9/10</Typography>*/}
                    {/*    <Typography variant={'body1'} mb={2}>本日發問客戶數</Typography>*/}
                    {/*    <Box sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: 'row',*/}
                    {/*        alignItems: 'flex-end',*/}
                    {/*        justifyContent: 'center'*/}
                    {/*    }}>*/}
                    {/*        <Typography variant={'h4'} fontWeight={'bold'} mr={1}>18</Typography>*/}
                    {/*        <Typography variant={'body1'} mb={1 / 2}>人</Typography>*/}
                    {/*    </Box>*/}
                    {/*</Card>*/}
                    {/*本日發問問題數*/}
                    <Grid container spacing={2} sx={{ mb: 8, alignItems: 'stretch' }}>
                        {['本日發問問題數', '本月發問問題數', '本月預估原始費用'].map((title, index) => (
                            <Grid item xs={12} md={6} lg={4} key={index}>
                                <Card sx={{
                                    minHeight: '168px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '32px 16px',
                                    boxSizing: 'border-box'
                                }}>
                                    <Typography variant="subtitle2" mb={1} color="#CBA660">
                                        {index === 0 ? dayjs().format('YYYY/MM/DD') : `${dayjs().startOf('month').format('YYYY/MM/DD')}-${dayjs().endOf('month').format('YYYY/MM/DD')}`}
                                    </Typography>
                                    <Typography variant="body1" textAlign={'left'} mb={2}>{title}</Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography variant="h4" fontWeight="bold" mr={1}>
                                            {index === 0 ? dashboardData?.todayTracesCount || '0' :
                                                index === 1 ? dashboardData?.monthTracesCount || '0' :
                                                    `NT$${dashboardData?.monthTotalModelCost.toFixed(2) || '0'}`}
                                        </Typography>
                                        {index < 2 && <Typography variant="body1" mb={1/2}>個</Typography>}
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/*日期範圍搜尋*/}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: '32px',
                    mb: 3,
                    pl: 0
                }}>
                    <Box mr={2} mb={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                value={dateRange}
                                onChange={handleDateRangeChange}
                                localeText={{ start: '開始日期', end: '結束日期' }}
                                sx={{width: '100%', maxWidth: '400px'}}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box mr={2} mb={2}>
                        <FormControl>
                            <InputLabel id="period-select-label">期間</InputLabel>
                            <Select
                                labelId="period-select-label"
                                id="period-select"
                                value={periodSelect}
                                label="期間"
                                onChange={handlePeriodSelectChange}
                                sx={{width: '220px'}}
                            >
                                <MenuItem value={1}>當日</MenuItem>
                                <MenuItem value={7}>一周</MenuItem>
                                <MenuItem value={30}>一個月</MenuItem>
                                <MenuItem value={90}>三個月</MenuItem>
                                <MenuItem value={180}>六個月</MenuItem>
                                <MenuItem value={365}>一年</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant="contained" onClick={fetchDashboardData} sx={{backgroundColor: '#CBA660', marginBottom: '16px','&:hover': { backgroundColor: '#B8935C'}}}>搜尋</Button>
                </Box>

                {/*趨勢圖*/}
                <Box>
                    <Stack direction="row" spacing={2}>
                        <Box>
                            <Typography variant={'h5'} fontWeight={'bold'} textAlign={'left'}
                                        mb={3}>發問問題數趨勢</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end', mb:3}}>
                                <Typography variant={'h5'} mr={2}>{tracesChartData.seriesData.reduce((sum, current) => sum + current, 0)}</Typography>
                                <Typography variant={'subtitle2'} textAlign={'left'} color={'gray'}>Traces tracked</Typography>
                            </Box>
                            {tracesChartData.xAxisData.length > 0 ? (
                                <LineChart
                                    sx={{
                                        '& .MuiAreaElement-series-test': {
                                            fill: "url('#myGradient')",
                                        },
                                    }}
                                    xAxis={[{
                                        data: tracesChartData.xAxisData,
                                        scaleType: 'time',
                                        valueFormatter: (date) => dayjs(date).format('YYYY-MM-DD')
                                    }]}
                                    series={[
                                        {
                                            id: "test",
                                            curve: "linear",
                                            showMark: true,
                                            area: true,
                                            color: "#CBA660",
                                            data: tracesChartData.seriesData,
                                            label: (`發問問題數(個)`)
                                        }
                                    ]}
                                    width={500}
                                    height={300}
                                >
                                    <defs>
                                        <linearGradient id="myGradient" gradientTransform="rotate(90)">
                                            <stop offset="0%" stopColor="#CBA660" stopOpacity={0.8}/>
                                            <stop offset="100%" stopColor="#CBA660" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </LineChart>
                            ) : (
                                <Typography>無有效數據可顯示</Typography>
                            )}
                        </Box>
                        <Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end', mb:3}}>
                                <Typography variant={'h5'} fontWeight={'bold'} textAlign={'left'}>費用趨勢</Typography>
                                <Typography variant={'body1'} fontWeight={'bold'} textAlign={'left'} color={'gray'}>(NT$)</Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end', mb:3}}>
                                <Typography variant={'h5'} mr={2}>{modelChartData.seriesData.reduce((sum, current) => sum + current, 0).toFixed(2)}</Typography>
                                {/*<Typography variant={'subtitle2'} textAlign={'left'} color={'gray'}>NT$</Typography>*/}
                            </Box>
                            {modelChartData.xAxisData.length > 0 ? (
                                <LineChart
                                    sx={{
                                        '& .MuiAreaElement-series-test': {
                                            fill: "url('#myGradient')",
                                        },
                                    }}
                                    xAxis={[{
                                        data: modelChartData.xAxisData,
                                        scaleType: 'time',
                                        valueFormatter: (date) => dayjs(date).format('YYYY-MM-DD')
                                    }]}
                                    series={[
                                        {
                                            id: "test",
                                            curve: "linear",
                                            showMark: false,
                                            area: true,
                                            color: "#CBA660",
                                            data: modelChartData.seriesData,
                                            label: (`總計預估費用`)
                                        }
                                    ]}
                                    width={500}
                                    height={300}
                                >
                                    <defs>
                                        <linearGradient id="myGradient" gradientTransform="rotate(90)">
                                            <stop offset="0%" stopColor="#CBA660" stopOpacity={0.8}/>
                                            <stop offset="100%" stopColor="#CBA660" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                </LineChart>

                            ) : (
                                <Typography>無有效數據可顯示</Typography>
                            )}
                        </Box>
                    </Stack>
                </Box>

            </Box>
        </Layout>
    );
};

export default Dashboard;
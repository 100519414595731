import { SizeEnum, DialogTypeEnum, ModeEnum } from "./libs";
import { setLocalStorage, getLocalStorage } from "./localStorage";
import moment from 'moment-timezone';

/**
 * 驗證email是否正確
 * @param {string} email email
 * @return {boolean}
 */
export const validEmail = (email) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return reg.test(email);
};

/**
 * 驗證email是否相同
 * @param {string} str email
 * @return {boolean}
 */
export const validSameEmail = (str) => {
    const email = JSON.parse(getLocalStorage('userInfo')).email;
    return str === email;
};

/**
 * 驗證字串是否正確
 * @param {string} str text
 * @param {number} [minLength=0] min length
 * @return {boolean}
 */
export const validString = (str, minLength = 0) => !!str && str.length >= minLength;

/**
 * 驗證密碼是否正確
 * @param {string} password password
 * @param {number} [minLength=8] min length
 * @return {boolean}
 */
export const validPassword = (password, minLength = 8) => validString(password, minLength);

/**
 * 驗證數字是否正確
 * @param {number} number number
 * @return {boolean}
 */
export const validNumber = (number) => !!number && number > 0;

/**
 * 驗證選擇的選項是否超過0
 * @param {number} v select value
 * @return {boolean}
 */
export const validSelectNum = (v) => v > 0;

/**
 * 驗證檔案大小是否正確
 * @param {File} file
 * @param {number} size
 * @param {SizeEnum} [sizeEnum=SizeEnum.KB]
 * @return {boolean}
 */
export const validFileSize = (file, size, sizeEnum = SizeEnum.KB) => !!file || (file.size / sizeEnum) <= size;

/**
 * 時間轉字串
 * @param {moment.Moment|Date|string|number|Array|Object} time Time
 * @param {string} format 時間格式
 * @param {string} [timeZone='Asia/Taipei'] 時區
 * @return {string}
 */
export const time2String = (time, format, timeZone = 'Asia/Taipei') =>
    time === null ? moment().tz(timeZone).format(format) : moment(time).tz(timeZone).format(format);

/**
 * 判斷物件是否為空。
 * @param {Object} obj
 * @return {boolean}
 */
export const objectIsEmpty = (obj) => obj === null || obj === undefined || Object.keys(obj).length === 0;

/**
 * 判断字符串是否為空。
 * @param {string} str - 要檢查的字串。
 * @returns {boolean} - 如果字串為 undefined 或長度為 0，則返回 true；否則返回 false。
 */
export const stringIsEmpty = (str) => str == null || str === "";

/**
 * 判斷數組是否為空。
 * @param {Array} arr - 要檢查的數組。
 * @returns {boolean} - 如果數組為 undefined 或長度為 0，則返回 true；否則返回 false。
 */
export const arrayIsEmpty = (arr) => arr === undefined || arr === null || arr.length === 0;

/**
 * 切換語言
 * @param {string} lang 語言
 * @return {void}
 */
export const changeLocale = (lang) => setLocalStorage('lang', lang);

/**
 * 取得所有年
 * @return {number[]}
 */
export const getFullYears = () => Array.from({length: new Date().getFullYear() - 1910}, (_, y) => y + 1911).sort((a, b) => b - a);

/**
 * 驗證檔案副檔名是否正確
 * @param {string} ext 副檔名
 * @param {string[]} extArray 副檔名陣列
 * @return {boolean}
 */
export const validFileExtension = (ext, extArray) => extArray.includes(ext);

/**
 * 跳轉至頁面頂部
 * @return {void}
 */
export const scrollToTop = () => {
    const y = document.documentElement.scrollTop || document.body.scrollTop;
    if (y > 0) {
        window.scrollTo(0, 0);
    }
};

/**
 * 取得檔案Base64
 * @param {File} file
 * @return {Promise}
 */
export const file2Base64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

/**
 * 下載檔案
 * @param {string} filename 檔案名稱
 * @param {string} fileType 檔案類型
 * @param {Blob|MediaSource} res response
 */
export const downloadFile = (filename, fileType, res) => {
    const blob = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = blob;
    link.download = `${filename}.${fileType}`;
    link.click();
    link.remove();
};

/**
 * 取得使用者當前語系轉換成支持的語言
 * @param {string|null} currentLang
 * @return {string}
 */
export const getSupportLang = (currentLang) => {
    let result;

    switch (currentLang) {
        case "zh-CN":
        case 'zh-SG':
            result = 'zh-CN';
            break;
        case 'de-AT':
        case 'de-DE':
        case 'de-LI':
        case 'de-LU':
        case 'de-CH':
            result = 'de-DE';
            break;
        case 'es-AR':
        case 'es-BO':
        case 'es-CL':
        case 'es-CO':
        case 'es-CR':
        case 'es-DO':
        case 'es-EC':
        case 'es-SV':
        case 'es-GT':
        case 'es-HN':
        case 'es-MX':
        case 'es-NI':
        case 'es-PA':
        case 'es-PY':
        case 'es-PE':
        case 'es-PR':
        case 'es-ES':
        case 'es-UY':
        case 'es-VE':
            result = 'es';
            break;
        case 'fi-FI':
            result = 'fi-FI';
            break;
        case 'fr-BE':
        case 'fr-CA':
        case 'fr-FR':
        case 'fr-LU':
        case 'fr-MC':
        case 'fr-CH':
            result = 'fr';
            break;
        case 'it-IT':
        case 'it-CH':
            result = 'it';
            break;
        case 'ja-JP':
            result = 'ja-JP';
            break;
        case 'ko-KR':
            result = 'ko-KR';
            break;
        case 'nl-BE':
        case 'nl-NL':
            result = 'nl';
            break;
        case 'th-TH':
            result = 'th-TH';
            break;
        case 'zh-HK':
        case 'zh-MO':
        case 'zh-TW':
            result = 'zh-TW';
            break;
        default:
            result = 'en-US';
            break;
    }

    return result;
};

/**
 * 上傳大頭貼列舉
 **/
export const UploadCoverEnum = Object.freeze({
    JPG: 'jpg',
    JPEG: 'jpeg',
    GIF: 'gif',
    PNG: 'png',
});

/**
 * 是否為開發模式
 * @return {boolean}
 */
export const isDebugMode = () => process.env.NODE_ENV === ModeEnum.Development;